import Form from 'react-bootstrap/Form';
import { useContext, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { PageLayout, PhotoBox, SaveButton } from '../../components';
import { routes, API_URL } from '../../conf';
import { useHandleFormSubmit } from '../../hooks/use-handle-form-submit';
import { inspectionPhotoFields } from './inspection-photo-fields';
import { Alert } from '../../components/alert.component';
import { GlobalContext } from '../../App';
import { TOAST_TYPE } from '../../hooks/use-toast';

export const InspectionPhotosPage = () => {
  const { technicalReviewId, toast } = useContext(GlobalContext);
  const navigate = useNavigate();
  const { triggerToast } = toast;
  const [pictureState, setPictureState] = useState({
    'car-back-left': '',
    'car-back-right': '',
    'car-front-left': '',
    'car-front-right': '',
    mileage: ''
  });

  const { handleSubmit, errorMessage, validated, isLoading } = useHandleFormSubmit(() =>
    axios
      .post(API_URL + '/api/scu/add-technical-review-pictures', {
        technical_review_id: technicalReviewId,
        picture_tail_right: pictureState['car-back-right'],
        picture_tail_left: pictureState['car-back-left'],
        picture_front_left: pictureState['car-front-left'],
        picture_front_right: pictureState['car-front-right'],
        picture_mileage: pictureState['mileage'],
        token: localStorage.getItem('token')
      })
      .then((response) => {
        if (response.data.success) {
          triggerToast('Zdjęcia zostały pomyślnie zapisane', TOAST_TYPE.SUCCESS);
          navigate(routes.inspectionResultPage);
        } else {
          const message = response.data.info;
          triggerToast(`${message || 'Nie udało się zapisać zdjęć - błąd wewnętrzny serwera'}`, TOAST_TYPE.DANGER);
        }
      })
      .catch(() => {
        triggerToast('Nie udało się zapisać zdjęć - błąd zewnętrzny serwera', TOAST_TYPE.DANGER);
      })
  );

  return (
    <PageLayout title="Przegląd techniczny">
      {/* <h2 className="text-center mt-5">Wykonaj zdjęcie pojazdu</h2> */}
      <h2 className="text-center mt-5">Zdjęcia pojazdu nie są wymagane</h2>
      <Form onSubmit={handleSubmit} noValidate validated={validated}>
        <Form.Group>
          <div className="d-flex flex-row flex-wrap justify-content-center">
            {inspectionPhotoFields.map((field) => (
              <PhotoBox
                required={field.required}
                errorMessage={errorMessage}
                key={field.id}
                id={field.id}
                placeholderImage={field.placeholderImage}
                title={field.title}
                setPictureState={setPictureState}
              />
            ))}
          </div>
          <Alert className="alert" message={errorMessage} />
          <SaveButton isLoading={isLoading} />
        </Form.Group>
      </Form>
    </PageLayout>
  );
};