import { useContext, useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';

import { GlobalContext } from '../../App';
import { SaveButton, PageLayout } from '../../components';
import { Alert } from '../../components/alert.component';
import { TOAST_TYPE } from '../../hooks/use-toast';
import { TIME_DELAY, API_URL, routes } from '../../conf';

export const ChooseSpeedCarWorker = () => {
  const { setSpeedCarWorker, toast } = useContext(GlobalContext);
  const { triggerToast } = toast;
  const [workers, setWorkers] = useState([]);
  const [selectedWorker, setSelectedWorker] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [targetRoute, setTargetRoute] = useState(routes.homepage);
  const isInspection = location.state && location.state.redirectTo === routes.inspectionInitPage;
  const isInsurance = location.state && location.state.redirectTo === routes.insuranceInitPage;

  useEffect(() => {
    if (location.state && location.state.redirectTo) {
      setTargetRoute(location.state.redirectTo);
    }
  }, [location]);

  const onChange = (e) => {
    const selectedUser = workers.find((user) => user.person_id === e.currentTarget.value);
    setSpeedCarWorker(selectedUser);
    setSelectedWorker(e.currentTarget.value);
  };

  useEffect(() => {
    axios
      .post(API_URL + '/api/scu/search/workers', { token: localStorage.getItem('token'), deviceId: localStorage.getItem('deviceId') })
      .then((response) => {
        const workersList = response.data.data;
        if (workersList.length === 0) {
          triggerToast('Urządzenie nie ma dodanych żadnych pracowników', TOAST_TYPE.DANGER, TIME_DELAY.LONG);
        }
        setWorkers(workersList);
        setLoading(false);
      })
      .catch(() => {
        triggerToast('Nie udało się pobrać listy pracowników - błąd serwera', TOAST_TYPE.DANGER, TIME_DELAY.LONG);
        setLoading(false);
      });
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitted(true);

    if (selectedWorker !== '') {
      triggerToast('Użytkownik skanujący został wybrany', TOAST_TYPE.SUCCESS);
      navigate(targetRoute);
    } else {
      triggerToast('Proszę wybrać użytkownika', TOAST_TYPE.DANGER);
    }
  };

  return (
    <PageLayout title="" withLogo={true}>
      <h1 className="main-title">{`Wybierz użytkownika ${isInspection || isInsurance ? 'skanującego' : 'wydającego'}`}</h1>
      <Form className="form-container" onSubmit={handleSubmit} noValidate>
        <Form.Group>
          <h1 className="input-label">{isInspection || isInsurance ? 'Użytkownik skanujący' : 'Użytkownik wydający'}</h1>
          <Form.Select 
            required={true} 
            type="select" 
            onChange={onChange}
            isInvalid={isSubmitted && selectedWorker === ''}
            className="input-type-select"
            disabled={loading}
          >
            <option value="">{loading ? 'Ładowanie...' : 'Wybierz'}</option>
            {!loading && workers.map((user) => (
              <option key={user.person_id} value={user.person_id}>
                {user.first_name + ' ' + user.surname}
              </option>
            ))}
          </Form.Select>
          {isSubmitted && selectedWorker === '' && (
            <Form.Control.Feedback type="invalid">
              <Alert message={'Wybierz użytkownika'} />
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <SaveButton />
      </Form>
    </PageLayout>
  );
};
