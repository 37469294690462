import { Button } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';

export const SaveButton = ({ isLoading, onClick, disabled = false }) => {
  return (
    <div className="w-100 d-flex justify-content-center mt-0 mb-0">
      <Button className="save-button" type="submit" onClick={onClick} disabled={disabled}>
        {isLoading ? (
          <div className="save-button-spinner-container">
            <Spinner as="span" animation="border" size="m" role="status" aria-hidden="true"/>
            <h2 className="save-button-text color-white d-inline ml-8" >
              Zapisywanie...
            </h2>
          </div>
        ) : (
          'Zapisz'
        )}
      </Button>
    </div>
  );
};