export const ableToTakeCarPhotoFields = [
  {
    id: 'ac_left_back',
    placeholderImage: 'l-t.png',
    title: 'Lewy tył',
    required: true
  },
  {
    id: 'ac_right_back',
    placeholderImage: 'p-t.png',
    title: 'Prawy tył',
    required: true
  },
  {
    id: 'ac_left_front',
    placeholderImage: 'p-p.png',
    title: 'Lewy przód',
    required: true
  },
  {
    id: 'ac_right_front',
    placeholderImage: 'l-p.png',
    title: 'Prawy przód',
    required: true
  },
  {
    id: 'ac_vin',
    placeholderImage: 'car-vin.png',
    title: 'VIN',
    required: true
  },
  {
    id: 'ac_key',
    placeholderImage: 'car-keys.png',
    title: 'Kluczyki',
    required: true
  },
  {
    id: 'ac_inside',
    placeholderImage: 'car-wheel.png',
    title: 'Wnętrze',
    required: true
  },
  {
    id: 'ac_mileage',
    placeholderImage: 'car-mileage.png',
    title: 'Zdjęcie przebiegu',
    required: true
  },
];
