import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { House, XCircle } from 'react-bootstrap-icons';

import { GlobalContext } from '../App';
import { ToastContainer } from '../containers/toast.container';
import { defaultUserInformationsState } from '../appState/default-user-information-state';
import { routes } from '../conf';

export const PageLayout = ({ children, title, withLogo = false, homepage = false }) => {
  const { setUserInformations, speedCarWorker } = useContext(GlobalContext);
  const navigate = useNavigate();
  const goToMainPage = () => {
    navigate('/');
    setUserInformations(defaultUserInformationsState);
  };

  const localStoragePlaceName = localStorage.getItem('placeName');
  const localStorageToken = localStorage.getItem('token');

  const handleDeletePlace = () => {
    navigate(routes.chooseSpeedCarPlace)
    localStorage.removeItem('token');
    localStorage.removeItem('placeName');
    localStorage.removeItem('deviceId');
  }

  const RenderHeaderHomePage = () => {
    return (
      <div className={'header justify-content-center'}>
        <div className="d-flex flex-row justify-content-center">
          <div className="d-flex flex-column align-items-center"> 
            <img alt="logo" src="skp_simple.png" className="logo"></img>
            {localStorageToken && (
              <div className="position-relative">
                <XCircle className="delete-place-button" onClick={handleDeletePlace} />
                {localStorageToken && (<h3 className="place-name text-center mt-4">{localStoragePlaceName}</h3>)}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }

  const RenderHeaderInnerPage = () => {
    return (
      <div className="header-container">
        <div className="header justify-content-start w-100">
          <div className="d-flex flex-column w-100">
            <div className="d-flex flex-row align-items-center w-100">
              <House className="home-button" onClick={() => goToMainPage()} />
              <h1 className="header-title text-center p-4 m-0 flex-grow-1">{title}</h1>
            </div>
            <h3 className="place-name text-center w-100">{speedCarWorker.first_name.length > 0 ? `${speedCarWorker.first_name} ${speedCarWorker.surname}` : "---"}</h3>
          </div>
        </div>
      </div>
    );
  };

  const RenderHeaderOuterPage = () => {
    return (
      <div className={`header justify-content-start`}>
        <House className="home-button" onClick={() => goToMainPage()} />
        <div className="d-flex flex-row justify-content-center">
          <div className="d-flex flex-column align-items-center"> 
            <img alt="logo" src="skp_simple.png" className="logo"></img>
            {localStorageToken ? (
              <div className="position-relative">
                <XCircle className="delete-place-button" onClick={handleDeletePlace} />
                {localStorageToken && (<h3 className="place-name text-center mt-4">{localStoragePlaceName}</h3>)}
              </div>
            ) : (
              <div className="position-relative">
                <h3 className="place-name text-center mt-4 hide-text-header">________________________________</h3>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="app-container d-flex flex-column justify-content-between">
      <ToastContainer />
      <div className="d-flex flex-column justify-content-start">
          {withLogo && homepage ? <RenderHeaderHomePage /> : withLogo ? <RenderHeaderOuterPage /> : <RenderHeaderInnerPage />}
        {children}
      </div>
    </div>
  );
};
