import { useState, useContext, useEffect, useCallback } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { PageLayout, PhotoBox, AztecContainer, AddPageButton, SaveButton, SearchUserPolicies } from '../../components';
import { GlobalContext } from '../../App';
import { routes, API_URL } from '../../conf';
import { initialScanPolicyPhotoFields, initialScanPolicyPhotoFieldsTwo, initialScanPolicyPhotoFieldsThree } from './initial-policy-fields';
import { initialScanOtherDocumentsFields } from './initial-other-documents-fields';
import { initialScanAnnexPhotoFields, initialScanAnnexPhotoFieldsTwo, initialScanAnnexPhotoFieldsThree } from './initial-annex-fields';
import { initialScanAccoutBooksPhotoFields, initialScanAccoutBooksPhotoFieldsTwo, initialScanAccoutBooksPhotoFieldsThree } from './initial-accout-books-fields';
import { initialCarOtherFields } from './initial-car-other-fields';
import { colors } from '../../shared/constants';
import { useHandleFormSubmit } from '../../hooks/use-handle-form-submit';
import { ableToTakeCarPhotoFields } from './policy-documentation-able-to-take-photo-fields';
import { Alert } from '../../components/alert.component';
import { TOAST_TYPE } from '../../hooks/use-toast';
import { initialTerminationsFields, initialTerminationsFieldsTwo, initialTerminationsFieldsThree } from './initial-terminations-fields';
import { useMultiplePhotoBox } from '../../hooks/use-multiple-photo-box';

export const PolicyDocumentationInitPage = () => {
  const navigate = useNavigate();
  const { userInformations, toast, speedCarWorker, isAnyPhotoBoxLoading } = useContext(GlobalContext);
  const { person_id, contractor_head, contractor_service } = speedCarWorker;
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const pickedPolicy = userInformations?.policies?.filter((policy) => policy.policy_no === selectedPolicy);

  const requiredFields =
    pickedPolicy?.length > 0 && Object.prototype.hasOwnProperty.call(pickedPolicy[0], 'required_fields')
      ? pickedPolicy[0].required_fields
      : [];
      
  const [selectedOption, setSelectedOption] = useState('reg_no');
  const [ableToTakeCarPhoto, setAbleToTakeCarPhoto] = useState(true);
  const [ableToTakeDocumentPhoto, setAbleToTakeDocumentPhoto] = useState(true);
  const bothQuestionsAnsweredNo = !ableToTakeCarPhoto && !ableToTakeDocumentPhoto;
  const [photoBoxStatuses, setPhotoBoxStatuses] = useState({});


  const handlePhotoBoxStatusChange = useCallback((id, status) => {
    setPhotoBoxStatuses((prevStatuses) => ({
      ...prevStatuses,
      [id]: status,
    }));
  }, []);

  const hasFailedPhotoBox = Object.values(photoBoxStatuses).includes('failed');

  const { 
    fields: policyFields,
    setFields: setAPolicyFields,
    uploadedFields: uploadedPolicyFields,
    setUploadedFields: setUploadedPolicyFields,
    uploadedValues: uploadedPolicyFieldsValues,
    handleAddPage: handleAddPolicyPage,
  } = useMultiplePhotoBox(initialScanPolicyPhotoFields);

  const { 
    fields: policyFieldsTwo,
    setFields: setAPolicyFieldsTwo,
    uploadedFields: uploadedPolicyFieldsTwo,
    setUploadedFields: setUploadedPolicyFieldsTwo,
    uploadedValues: uploadedPolicyFieldsValuesTwo,
    handleAddPage: handleAddPolicyPageTwo,
  } = useMultiplePhotoBox(initialScanPolicyPhotoFieldsTwo);

  const { 
    fields: policyFieldsThree,
    setFields: setAPolicyFieldsThree,
    uploadedFields: uploadedPolicyFieldsThree,
    setUploadedFields: setUploadedPolicyFieldsThree,
    uploadedValues: uploadedPolicyFieldsValuesThree,
    handleAddPage: handleAddPolicyPageThree,
  } = useMultiplePhotoBox(initialScanPolicyPhotoFieldsThree);

  const {
    fields: terminationFields,
    setFields: setTerminationFields,
    uploadedFields: uploadedTerminationFields,
    setUploadedFields: setUploadedTerminationFields,
    uploadedValues: uploadedTerminationFieldsValues,
    handleAddPage: handleAddTerminationPage,
  } = useMultiplePhotoBox(initialTerminationsFields);

  const {
    fields: terminationFieldsTwo,
    setFields: setTerminationFieldsTwo,
    uploadedFields: uploadedTerminationFieldsTwo,
    setUploadedFields: setUploadedTerminationFieldsTwo,
    uploadedValues: uploadedTerminationFieldsValuesTwo,
    handleAddPage: handleAddTerminationPageTwo,
  } = useMultiplePhotoBox(initialTerminationsFieldsTwo);

  const {
    fields: terminationFieldsThree,
    setFields: setTerminationFieldsThree,
    uploadedFields: uploadedTerminationFieldsThree,
    setUploadedFields: setUploadedTerminationFieldsThree,
    uploadedValues: uploadedTerminationFieldsValuesThree,
    handleAddPage: handleAddTerminationPageThree,
  } = useMultiplePhotoBox(initialTerminationsFieldsThree);

  const {
    fields: otherDocumentsFields,
    setFields: setOtherDocumentsFields,
    uploadedFields: uploadedOtherDocumentsFields,
    setUploadedFields: setUploadedOtherDocumentsFields,
    uploadedValues: uploadedOtherDocumentsFieldsValues,
    handleAddPage: handleAddOtherPage,
  } = useMultiplePhotoBox(initialScanOtherDocumentsFields);

  const {
    fields: annexesFields,
    setFields: setAnnexPhotoState,
    uploadedFields: uploadedAnnexesFields,
    setUploadedFields: setUploadedAnnexesFields,
    uploadedValues: uploadedAnexesFieldsValues,
    handleAddPage: handleAddAnnexPage,
  } = useMultiplePhotoBox(initialScanAnnexPhotoFields);

  const {
    fields: annexesFieldsTwo,
    setFields: setAnnexPhotoStateTwo,
    uploadedFields: uploadedAnnexesFieldsTwo,
    setUploadedFields: setUploadedAnnexesFieldsTwo,
    uploadedValues: uploadedAnexesFieldsValuesTwo,
    handleAddPage: handleAddAnnexPageTwo
  } = useMultiplePhotoBox(initialScanAnnexPhotoFieldsTwo);

  const {
    fields: annexesFieldsThree,
    setFields: setAnnexPhotoStateThree,
    uploadedFields: uploadedAnnexesFieldsThree,
    setUploadedFields: setUploadedAnnexesFieldsThree,
    uploadedValues: uploadedAnexesFieldsValuesThree,
    handleAddPage: handleAddAnnexPageThree
  } = useMultiplePhotoBox(initialScanAnnexPhotoFieldsThree);

  const {
    fields: accountBooksPhotoState,
    setFields: setAccountBooksPhotoState,
    uploadedFields: uploadedAccountBooksFields,
    setUploadedFields: setUploadedAccountBooksFields,
    uploadedValues: uploadedAccountBooksFieldsValues,
    handleAddPage: handleAddAccountBooksPage,
  } = useMultiplePhotoBox(initialScanAccoutBooksPhotoFields);

  const {
    fields: accountBooksPhotoStateTwo,
    setFields: setAccountBooksPhotoStateTwo,
    uploadedFields: uploadedAccountBooksFieldsTwo,
    setUploadedFields: setUploadedAccountBooksFieldsTwo,
    uploadedValues: uploadedAccountBooksFieldsValuesTwo,
    handleAddPage: handleAddAccountBooksPageTwo,
  } = useMultiplePhotoBox(initialScanAccoutBooksPhotoFieldsTwo);

  const {
    fields: accountBooksPhotoStateThree,
    setFields: setAccountBooksPhotoStateThree,
    uploadedFields: uploadedAccountBooksFieldsThree,
    setUploadedFields: setUploadedAccountBooksFieldsThree,
    uploadedValues: uploadedAccountBooksFieldsValuesThree,
    handleAddPage: handleAddAccountBooksPageThree,
  } = useMultiplePhotoBox(initialScanAccoutBooksPhotoFieldsThree);

  const {
    fields: carOtherFields,
    setFields: setCarOtherFields,
    uploadedFields: uploadedCarOtherFields,
    setUploadedFields: setUploadedCarOtherFields,
    uploadedValues: uploadedCarOtherFieldsValues,
    handleAddPage: handleAddOtherCarPhotos,
  } = useMultiplePhotoBox(initialCarOtherFields);
  
  const filteredCarPhotosFields = ableToTakeCarPhotoFields.filter((field) => requiredFields.hasOwnProperty(field.id));
  const [carPictureState, setCarPictureState] = useState({
    ac_left_back: '',
    ac_right_back: '',
    ac_left_front: '',
    ac_right_front: '',
    ac_vin: '',
    ac_key: '',
    ac_inside: '',
    ac_mileage: ''
  });

  const { triggerToast } = toast;

  const { handleSubmit, errorMessage, validated, isLoading } = useHandleFormSubmit(() => {
    if (hasFailedPhotoBox) {
      triggerToast('Nie udało się zapisać zdjęć - nie wszystkie zdjęcia zostały zapisane', TOAST_TYPE.DANGER);
      return Promise.reject('Nie udało się zapisać zdjęć - nie wszystkie zdjęcia zostały zapisane');
    }
  
    axios
      .post(API_URL + '/api/scu/add-policy-documents', {
        policy_id: pickedPolicy[0]?.policy_id,
        policies: [uploadedPolicyFieldsValues, uploadedPolicyFieldsValuesTwo, uploadedPolicyFieldsValuesThree],
        terminations: [uploadedTerminationFieldsValues, uploadedTerminationFieldsValuesTwo, uploadedTerminationFieldsValuesThree],
        ac_left_front: carPictureState['ac_left_front'],
        ac_right_front: carPictureState['ac_right_front'],
        ac_left_back: carPictureState['ac_left_back'],
        ac_right_back: carPictureState['ac_right_back'],
        ac_vin: carPictureState['ac_vin'],
        ac_key: carPictureState['ac_key'],
        ac_inside: carPictureState['ac_inside'],
        ac_mileage: carPictureState['ac_mileage'],
        ac_pictures: [uploadedCarOtherFieldsValues],
        annexes: [uploadedAnexesFieldsValues, uploadedAnexesFieldsValuesTwo, uploadedAnexesFieldsValuesThree],
        contribution_rebooks: [uploadedAccountBooksFieldsValues, uploadedAccountBooksFieldsValuesTwo, uploadedAccountBooksFieldsValuesThree],
        other: [uploadedOtherDocumentsFieldsValues],
        token: localStorage.getItem('token'),
        person: {
          person_id,
          contractor_head,
          contractor_service
        }
      })
      .then((response) => {
        if (response.data.success) {
          triggerToast('Zdjęcia zostały pomyślnie zapisane', TOAST_TYPE.SUCCESS);
          navigate(routes.homepage);
        } else {
          const message = response.data.info;
          triggerToast(`${message || 'Nie udało się zapisać zdjęć - błąd wewnętrzny serwera'}`, TOAST_TYPE.DANGER);
        }
      })
      .catch(() => {
        triggerToast('Nie udało się zapisać zdjęć - błąd zewnętrzny serwera', TOAST_TYPE.DANGER);
      })
});

  useEffect(() => {
    if (userInformations?.policies?.length === 1) {
      setSelectedPolicy(userInformations.policies[0]?.policy_no);
    } else {
      setSelectedPolicy(null);
    }
  }, [userInformations.policies]);

  const policyHasAc = pickedPolicy[0]?.has_ac && filteredCarPhotosFields.length > 0;

  return (
    <PageLayout title="Dokumentacja polisowa">
      <div className="aztec-container d-flex justify-content-between" style={styles.header}>
        <AztecContainer />
        <SearchUserPolicies selectedOption={selectedOption} setSelectedOption={setSelectedOption} />
      </div>

      <Form onSubmit={handleSubmit} noValidate validated={validated}>
        <Form.Group className="form-container">
          <h1 className="text-center mt-5">Dotyczy polisy</h1>
          <Form.Select
            size="lg"
            aria-label="select-policy"
            className="input-type-select"
            onChange={(e) => {
              setSelectedPolicy(e.currentTarget.value);
            }}
            required={true}
            value={selectedPolicy}
          >
            { 
              <option key="" value="">
                Wybierz
              </option>
            }
            {userInformations?.policies.map((policy) => {
              const { policy_id, policy_no } = policy;
              return <option key={policy_id}>{policy_no}</option>;
            })}
          </Form.Select>
        </Form.Group>
        {selectedPolicy && (
          <>
            <>
            <div className="d-flex flex-row justify-content-around mt-4 mb-3">
              <h3 className="text-center">Czy możesz teraz wykonać zdjęcie dokumentów?</h3>
            </div>
            <div className="d-flex flex-row justify-content-around">
              <Button
                style={ableToTakeDocumentPhoto ? styles.buttonPositiveClicked : styles.buttonPositive}
                onClick={() => setAbleToTakeDocumentPhoto(true)}>
                Tak
              </Button>

              <Button
                required
                style={!ableToTakeDocumentPhoto && ableToTakeDocumentPhoto !== null ? styles.buttonNegativeClicked : styles.buttonNegative}
                onClick={() => setAbleToTakeDocumentPhoto(false)}>
                Nie
              </Button>
              </div>
            </>
            {ableToTakeDocumentPhoto && (
              <>
                {requiredFields.hasOwnProperty('policies') && requiredFields.policies >= 1 && (
                <div className="d-flex flex-column justify-content-center">
                  <h1 className="text-center mt-4">Skanuj polise</h1>
                  <div className="d-flex flex-row justify-content-around flex-wrap">
                    {policyFields.map((field) => (
                      <PhotoBox
                        errorMessage={errorMessage}
                        required={field.required}
                        key={field.id}
                        id={field.id}
                        placeholderImage={field.placeholderImage}
                        title={field.title}
                        generated={field.generated}
                        state={policyFields}
                        pictureState={uploadedPolicyFields}
                        setState={setAPolicyFields}
                        setPictureState={setUploadedPolicyFields}
                        onStatusChange={handlePhotoBoxStatusChange}
                      />
                    ))}
                    <AddPageButton action={handleAddPolicyPage} fieldToAdd={policyFields?.length} />
                  </div>
                </div>
              )}
              {requiredFields.hasOwnProperty('policies') && requiredFields.policies >= 2 && (
                <div className="d-flex flex-column justify-content-center">
                  <h1 className="text-center mt-4">Skanuj polise 2</h1>
                  <div className="d-flex flex-row justify-content-around flex-wrap">
                    {policyFieldsTwo.map((field) => (
                      <PhotoBox
                        errorMessage={errorMessage}
                        required={field.required}
                        key={field.id}
                        id={field.id}
                        placeholderImage={field.placeholderImage}
                        title={field.title}
                        generated={field.generated}
                        state={policyFieldsTwo}
                        pictureState={uploadedPolicyFieldsTwo}
                        setState={setAPolicyFieldsTwo}
                        setPictureState={setUploadedPolicyFieldsTwo}
                        onStatusChange={handlePhotoBoxStatusChange}
                      />
                    ))}
                    <AddPageButton action={handleAddPolicyPageTwo} fieldToAdd={policyFieldsTwo?.length} />
                  </div>
                </div>
              )}
              {requiredFields.hasOwnProperty('policies') && requiredFields.policies >= 3 && (
                <div className="d-flex flex-column justify-content-center">
                  <h1 className="text-center mt-4">Skanuj polise 3</h1>
                  <div className="d-flex flex-row justify-content-around flex-wrap">
                    {policyFieldsThree.map((field) => (
                      <PhotoBox
                        errorMessage={errorMessage}
                        required={field.required}
                        key={field.id}
                        id={field.id}
                        placeholderImage={field.placeholderImage}
                        title={field.title}
                        generated={field.generated}
                        state={policyFieldsThree}
                        pictureState={uploadedPolicyFieldsThree}
                        setState={setAPolicyFieldsThree}
                        setPictureState={setUploadedPolicyFieldsThree}
                        onStatusChange={handlePhotoBoxStatusChange}
                      />
                    ))}
                    <AddPageButton action={handleAddPolicyPageThree} fieldToAdd={policyFieldsThree?.length} />
                  </div>
                </div>
              )}
              {requiredFields.hasOwnProperty('terminations') && requiredFields.terminations >= 1 && (
                <div className="d-flex flex-column justify-content-center">
                  <h1 className="text-center mt-4">{`Skanuj wypowiedzenie ${pickedPolicy[0].policy_no}`}</h1>
                  <div className="d-flex flex-row justify-content-around flex-wrap">
                    {terminationFields.map((field) => (
                      <PhotoBox
                        errorMessage={errorMessage}
                        required={field.required}
                        key={field.id}
                        id={field.id}
                        placeholderImage={field.placeholderImage}
                        title={field.title}
                        generated={field.generated}
                        state={terminationFields}
                        pictureState={uploadedTerminationFields}
                        setState={setTerminationFields}
                        setPictureState={setUploadedTerminationFields}
                        onStatusChange={handlePhotoBoxStatusChange}
                      />
                    ))}
                    <AddPageButton action={handleAddTerminationPage} fieldToAdd={terminationFields?.length} />
                  </div>
                </div>
              )}
              {requiredFields.hasOwnProperty('terminations') && requiredFields.terminations >= 2 && (
                <div className="d-flex flex-column justify-content-center">
                  <h1 className="text-center mt-4">{`Skanuj wypowiedzenie 2 ${pickedPolicy[0].policy_no}`}</h1>
                  <div className="d-flex flex-row justify-content-around flex-wrap">
                    {terminationFieldsTwo.map((field) => (
                      <PhotoBox
                        errorMessage={errorMessage}
                        required={field.required}
                        key={field.id}
                        id={field.id}
                        placeholderImage={field.placeholderImage}
                        title={field.title}
                        generated={field.generated}
                        state={terminationFieldsTwo}
                        pictureState={uploadedTerminationFieldsTwo}
                        setState={setTerminationFieldsTwo}
                        setPictureState={setUploadedTerminationFieldsTwo}
                        onStatusChange={handlePhotoBoxStatusChange}
                      />
                    ))}
                    <AddPageButton action={handleAddTerminationPageTwo} fieldToAdd={terminationFieldsTwo?.length} />
                  </div>
                </div>
              )}
              {requiredFields.hasOwnProperty('terminations') && requiredFields.terminations >= 3 && (
                <div className="d-flex flex-column justify-content-center">
                  <h1 className="text-center mt-4">{`Skanuj wypowiedzenie 3 ${pickedPolicy[0].policy_no}`}</h1>
                  <div className="d-flex flex-row justify-content-around flex-wrap">
                    {terminationFieldsThree.map((field) => (
                      <PhotoBox
                        errorMessage={errorMessage}
                        required={field.required}
                        key={field.id}
                        id={field.id}
                        placeholderImage={field.placeholderImage}
                        title={field.title}
                        generated={field.generated}
                        state={terminationFieldsThree}
                        pictureState={uploadedTerminationFieldsThree}
                        setState={setTerminationFieldsThree}
                        setPictureState={setUploadedTerminationFieldsThree}
                        onStatusChange={handlePhotoBoxStatusChange}
                      />
                    ))}
                    <AddPageButton action={handleAddTerminationPageThree} fieldToAdd={terminationFieldsThree?.length} />
                  </div>
                </div>
              )}
              {requiredFields.hasOwnProperty('annexes') && requiredFields.annexes >= 1 && (
                <div className="d-flex flex-column justify-content-center">
                  <h1 className="text-center mt-4">Skanuj Aneks</h1>
                  <div className="d-flex flex-row flex-wrap justify-content-around">
                    {annexesFields.map((field) => (
                      <PhotoBox
                        errorMessage={errorMessage}
                        required={field.required}
                        key={field.id}
                        id={field.id}
                        placeholderImage={field.placeholderImage}
                        title={field.title}
                        generated={field.generated}
                        state={annexesFields}
                        setPictureState={setUploadedAnnexesFields}
                        setState={setAnnexPhotoState}
                        pictureState={uploadedAnnexesFields}
                        onStatusChange={handlePhotoBoxStatusChange}
                      />
                    ))}
                    <AddPageButton action={handleAddAnnexPage} fieldToAdd={annexesFields?.length} />
                  </div>
                </div>
                )}
                {requiredFields.hasOwnProperty('annexes') && requiredFields.annexes >= 2 && (
                  <div className="d-flex flex-column justify-content-center">
                    <h1 className="text-center mt-4">Skanuj Aneks 2</h1>
                    <div className="d-flex flex-row flex-wrap justify-content-around">
                      {annexesFieldsTwo.map((field) => (
                        <PhotoBox
                          errorMessage={errorMessage}
                          required={field.required}
                          key={field.id}
                          id={field.id}
                          placeholderImage={field.placeholderImage}
                          title={field.title}
                          generated={field.generated}
                          state={annexesFieldsTwo}
                          setPictureState={setUploadedAnnexesFieldsTwo}
                          setState={setAnnexPhotoStateTwo}
                          pictureState={uploadedAnnexesFieldsTwo}
                          onStatusChange={handlePhotoBoxStatusChange}
                        />
                      ))}
                      <AddPageButton action={handleAddAnnexPageTwo} fieldToAdd={annexesFieldsTwo?.length} />
                    </div>
                  </div>
                )}
                {requiredFields.hasOwnProperty('annexes') && requiredFields.annexes >= 3 && (
                  <div className="d-flex flex-column justify-content-center">
                    <h1 className="text-center mt-4">Skanuj Aneks 3</h1>
                    <div className="d-flex flex-row flex-wrap justify-content-around">
                      {annexesFieldsThree.map((field) => (
                        <PhotoBox
                          errorMessage={errorMessage}
                          required={field.required}
                          key={field.id}
                          id={field.id}
                          placeholderImage={field.placeholderImage}
                          title={field.title}
                          generated={field.generated}
                          state={annexesFieldsThree}
                          setPictureState={setUploadedAnnexesFieldsThree}
                          setState={setAnnexPhotoStateThree}
                          pictureState={uploadedAnnexesFieldsThree}
                          onStatusChange={handlePhotoBoxStatusChange}
                        />
                      ))}
                      <AddPageButton action={handleAddAnnexPageThree} fieldToAdd={annexesFieldsThree?.length} />
                    </div>
                  </div>
                )}
                {requiredFields.hasOwnProperty('contribution_rebooks') && requiredFields.contribution_rebooks >= 1 && (
                  <div className="d-flex flex-column justify-content-around">
                    <h1 className="text-center mt-4">Przeksięgowanie składki</h1>
                    <div className="d-flex flex-row flex-wrap justify-content-around">
                      {accountBooksPhotoState.map((field) => (
                        <PhotoBox
                          errorMessage={errorMessage}
                          required={field.required}
                          key={field.id}
                          id={field.id}
                          placeholderImage={field.placeholderImage}
                          title={field.title}
                          generated={field.generated}
                          state={accountBooksPhotoState}
                          setPictureState={setUploadedAccountBooksFields}
                          setState={setAccountBooksPhotoState}
                          pictureState={uploadedAccountBooksFields}
                          onStatusChange={handlePhotoBoxStatusChange}
                        />
                      ))}
                      <AddPageButton action={handleAddAccountBooksPage} fieldToAdd={accountBooksPhotoState?.length} />
                    </div>
                  </div>
                )}
                {requiredFields.hasOwnProperty('contribution_rebooks') && requiredFields.contribution_rebooks >= 2 && (
                  <div className="d-flex flex-column justify-content-around">
                    <h1 className="text-center mt-4">Przeksięgowanie składki 2</h1>
                    <div className="d-flex flex-row flex-wrap justify-content-around">
                      {accountBooksPhotoStateTwo.map((field) => (
                        <PhotoBox
                          errorMessage={errorMessage}
                          required={field.required}
                          key={field.id}
                          id={field.id}
                          placeholderImage={field.placeholderImage}
                          title={field.title}
                          generated={field.generated}
                          state={accountBooksPhotoStateTwo}
                          setPictureState={setUploadedAccountBooksFieldsTwo}
                          setState={setAccountBooksPhotoStateTwo}
                          pictureState={uploadedAccountBooksFieldsTwo}
                          onStatusChange={handlePhotoBoxStatusChange}
                        />
                      ))}
                      <AddPageButton action={handleAddAccountBooksPageTwo} fieldToAdd={accountBooksPhotoStateTwo?.length} />
                    </div>
                  </div>
                )}
                {requiredFields.hasOwnProperty('contribution_rebooks') && requiredFields.contribution_rebooks >= 3 && (
                  <div className="d-flex flex-column justify-content-around">
                    <h1 className="text-center mt-4">Przeksięgowanie składki 3</h1>
                    <div className="d-flex flex-row flex-wrap justify-content-around">
                      {accountBooksPhotoStateThree.map((field) => (
                        <PhotoBox
                          errorMessage={errorMessage}
                          required={field.required}
                          key={field.id}
                          id={field.id}
                          placeholderImage={field.placeholderImage}
                          title={field.title}
                          generated={field.generated}
                          state={accountBooksPhotoStateThree}
                          setPictureState={setUploadedAccountBooksFieldsThree}
                          setState={setAccountBooksPhotoStateThree}
                          pictureState={uploadedAccountBooksFieldsThree}
                          onStatusChange={handlePhotoBoxStatusChange}
                        />
                      ))}
                      <AddPageButton action={handleAddAccountBooksPageThree} fieldToAdd={accountBooksPhotoStateThree?.length} />
                    </div>
                  </div>
                )}
              <div className="d-flex flex-column justify-content-around flex-wrap">
                <div className="d-flex flex-column justify-content-around">
                  <h1 className="text-center mt-4">Skanuj Inne</h1>
                  <div className="d-flex flex-row flex-wrap justify-content-around">
                    {otherDocumentsFields.map((field) => (
                      <PhotoBox
                        errorMessage={errorMessage}
                        required={field.required}
                        key={field.id}
                        id={field.id}
                        placeholderImage={field.placeholderImage}
                        title={field.title}
                        generated={field.generated}
                        state={otherDocumentsFields}
                        setPictureState={setUploadedOtherDocumentsFields}
                        setState={setOtherDocumentsFields}
                        pictureState={uploadedOtherDocumentsFields}
                        onStatusChange={handlePhotoBoxStatusChange}
                      />
                    ))}
                    <AddPageButton action={handleAddOtherPage} fieldToAdd={otherDocumentsFields?.length} />
                  </div>
                </div>
              </div>
            </>
            )}
            <Form.Group>
              {(policyHasAc) && (
                <>
                  <div className="d-flex flex-row justify-content-around mt-4 mb-3">
                    <h3 className="text-center">Czy możesz teraz wykonać zdjęcie pojazdu?</h3>
                  </div>
                  <div className="d-flex flex-row justify-content-around">
                    <Button
                      style={ableToTakeCarPhoto ? styles.buttonPositiveClicked : styles.buttonPositive}
                      onClick={() => setAbleToTakeCarPhoto(true)}>
                      Tak
                    </Button>

                    <Button
                      required
                      style={!ableToTakeCarPhoto && ableToTakeCarPhoto !== null ? styles.buttonNegativeClicked : styles.buttonNegative}
                      onClick={() => setAbleToTakeCarPhoto(false)}>
                      Nie
                    </Button>
                  </div>

                  {ableToTakeCarPhoto && (
                    <>
                      <h1 className="text-center mt-4">Zdjecia pojazdu</h1>
                      <div className="d-flex flex-row flex-wrap justify-content-around">
                        {filteredCarPhotosFields.map((field) => (
                          <PhotoBox
                            required={field.required}
                            errorMessage={errorMessage}
                            key={field.id}
                            id={field.id}
                            placeholderImage={field.placeholderImage}
                            title={field.title}
                            generated={field.generated}
                            setPictureState={setCarPictureState}
                            onStatusChange={handlePhotoBoxStatusChange}
                          />
                        ))}
                        {carOtherFields.map((field) => (
                          <PhotoBox
                            errorMessage={errorMessage}
                            required={field.required}
                            key={field.id}
                            id={field.id}
                            placeholderImage={field.placeholderImage}
                            title={field.title}
                            generated={field.generated}
                            state={carOtherFields}
                            setPictureState={setUploadedCarOtherFields}
                            setState={setCarOtherFields}
                            pictureState={uploadedCarOtherFields}
                            onStatusChange={handlePhotoBoxStatusChange}
                          />
                        ))}
                        <AddPageButton action={handleAddOtherCarPhotos} fieldToAdd={carOtherFields.length} />
                      </div>
                    </>
                  )}
                </>
              )}
          </Form.Group>
        </>
      )}
        <Alert className="text-danger text-center" style={styles.alert} message={errorMessage} />
        <SaveButton isLoading={isLoading} disabled={policyHasAc ? (isAnyPhotoBoxLoading || bothQuestionsAnsweredNo) : (isAnyPhotoBoxLoading || !ableToTakeDocumentPhoto)} />
      </Form>
    </PageLayout>
  );
};

const styles = {
  header: {
    padding: '2rem',
    backgroundColor: colors.accent
  },
  buttonPositive: {
    backgroundColor: colors.positive,
    border: `5px solid ${colors.positive}`,
    borderRadius: '5px',
    width: '20rem',
    height: '8rem',
    marginBottom: '1rem',
    fontSize: '2.2rem'
  },
  buttonNegative: {
    backgroundColor: colors.negative,
    border: `5px solid ${colors.negative}`,
    borderRadius: '5px',
    width: '20rem',
    height: '8rem',
    fontSize: '2.2rem'
  },
  buttonPositiveClicked: {
    backgroundColor: colors.positive,
    borderRadius: '5px',
    width: '20rem',
    height: '8rem',
    marginBottom: '1rem',
    border: `5px solid ${colors.accent}`,
    fontSize: '2.2rem'
  },
  buttonNegativeClicked: {
    backgroundColor: colors.negative,
    borderRadius: '5px',
    width: '20rem',
    height: '8rem',
    border: `5px solid ${colors.accent}`,
    fontSize: '2.2rem'
  },
  optionButton: {
    backgroundColor: colors.accent,
    color: colors.white,
    width: '70vw',
    height: '8rem',
    fontSize: '1.8rem'
  },
  styledInput: {
    height: '7rem',
    fontSize: '2rem',
    marginBottom: '2rem'
  },
  selectPolicy: {
    height: '6rem',
    fontSize: '1.8rem'
  },
  alert: {
    marginTop: '1.5rem'
  }
};
