import { useState, useContext } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import axios from 'axios';

import { GlobalContext } from '../App';
import { TOAST_TYPE } from '../hooks/use-toast';
import { TIME_DELAY, API_URL } from '../conf';

export const SearchUserPolicies = ({ selectedOption, setSelectedOption }) => {
  const { setUserInformations, toast } = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const { triggerToast } = toast;

  const handleClick = (e) => {
    e.preventDefault();
    setIsLoading(true);
    axios
      .post(API_URL + '/api/scu/get-policies', {
        [selectedOption]: searchValue,
        token: localStorage.getItem('token')
      })
      .then((response) => {
        const responseDataExist = response.data.data;
        if (responseDataExist) {
          const userData = response.data.data;
          if (userData.length > 0) {
            triggerToast('Wczytano dostępne polisy', TOAST_TYPE.SUCCESS);
          } else {
            triggerToast('Użytkownik nie posiada polis', TOAST_TYPE.DANGER);
          }
          setUserInformations({
            policies: userData
          });
        } else {
          const errorMessage = response.data.info || 'Nie znaleziono klienta w systemie';
          triggerToast(errorMessage, TOAST_TYPE.DANGER, TIME_DELAY.LONG);
          setUserInformations({ policies: [] });
        }
      })
      .catch(() => {
        triggerToast('Nie udało się pobrać danych klienta - błąd zewnętrzny serwera', TOAST_TYPE.DANGER, TIME_DELAY.LONG);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="d-flex flex-column justify-content-between">
      <Form onSubmit={handleClick} className="d-flex flex-column justify-content-between">
        <Form.Group>
          <div className="mb-2">
            <h2 className='registry-number-label'>Wyszukaj po:</h2>
            <Form.Check
              type="radio"
              label={<span className='radio-label'>Nr rejestracyjnym</span>}
              name="options"
              value="reg_no"
              checked={selectedOption === 'reg_no'}
              onChange={handleOptionChange}
            />

            <Form.Check
              type="radio"
              label={<span className='radio-label'>Nr polisy</span>}
              name="options"
              value="policy_no"
              checked={selectedOption === 'policy_no'}
              onChange={handleOptionChange}
            />
            <Form.Check
              type="radio"
              label={<span className='radio-label'>Nr telefonu</span>}
              name="options"
              value="phone"
              checked={selectedOption === 'phone'}
              onChange={handleOptionChange}
            />
          </div>
          <Form.Control
            type="text"
            onChange={(e) => setSearchValue(e.currentTarget.value)}
            className="input-type-text text-uppercase"
          />
        </Form.Group>
        <Button className="search-user-button" onClick={handleClick}>
          {isLoading ? (
            <div className="spinner-container">
              <Spinner as="span" animation="border" size="m" role="status" aria-hidden="true" />
              <h2 className="spinner-text color-white d-inline ml-8">
                Wyszukiwanie...
              </h2>
            </div>
          ) : (
            <h2 className="search-text color-white">
              Wyszukaj
            </h2>
          )}
        </Button>
      </Form>
    </div>
  );
};